/*
 * @Author: 最刚
 * @Date: 2020-11-05 11:56:51
 * @LastEditTime: 2020-11-05 15:23:17
 * @LastEditors: 最刚
 * @Description: WebVitals指标获取
 */
const logWebVitals = () => {
  import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(console.log); // Cumulative Layout Shift (CLS) 累积布局偏移<0.1
    getFID(console.log); // First Input Delay (FID) 用户第一次与页面交互,到浏览器实际能够开始处理响应该交互的事件处理程序的时间<100ms
    getFCP(console.log); // First Contentful Paint (FCP) 从页面开始加载到页面内容的任何部分在屏幕上呈现,“内容”是指文本、图像（包括背景图像）
    getLCP(console.log); // Largest Contentful Paint (LCP) 最大内容绘制 <2.5sec
    getTTFB(console.log); // Time to First Byte (TTFB) 用户浏览器接收页面内容的第一个字节所需的时间,服务器响应时间 <600ms
  });
};

export default logWebVitals;
