import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import { useSetRecoilState } from "recoil";
import { RoutesOption } from "@flx/react-router";
import { menuKey } from "@/recoil/atoms";
import styles from "./container.module.styl";

import ContainerMenu from "./menu";
import ContainerHeader from "./header";

const { Content, Sider } = Layout;

interface ContainerProps {
  children: React.ReactNode;
  route: RoutesOption;
}

function Container(props: ContainerProps) {
  let location = useLocation();
  const { route, children } = props;
  const setSelectedKey = useSetRecoilState(menuKey);

  useEffect(() => {
    console.log(route);
    setSelectedKey(location?.pathname);
  }, []);

  return (
    <Layout className={styles.cmptContainerWrapper}>
      <ContainerHeader parentPath={route?.path} routes={route?.routes} />
      <Layout className={styles.mainWrapper}>
        <Sider
          theme="light"
          className={styles.siderWrapper}
          style={{ border: "20px solid rgb(240, 242, 245)" }}
        >
          <ContainerMenu parentPath={route?.path} routes={route?.routes} />
        </Sider>
        <Content className={styles.contentWrapper}>
          <div className={styles.childrenWrapper}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Container;
