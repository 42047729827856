import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./index.styl";
import logWebVitals from "./logWebVitals";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </ConfigProvider>,
  document.getElementById("root")
);

// 打印WebVitals结果
logWebVitals();
