import React, { useEffect } from "react";
import { Menu } from "antd";
import Icon from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { useRecoilState } from "recoil";
import { menuKey } from "@/recoil/atoms";
import { RoutesOption } from "@flx/react-router";
import { useHistory, useLocation } from "react-router-dom";

const { SubMenu } = Menu;

interface ContainerMenuInterface {
  routes?: RoutesOption[];
  parentPath?: string;
}

function ContainerMenu(props: ContainerMenuInterface) {
  const { routes, parentPath } = props;
  const [selectedKey, setSelectedKey] = useRecoilState(menuKey);
  let location = useLocation();
  let history = useHistory();

  const handleClickSiderMenu = (info: MenuInfo) => {
    setSelectedKey(info.key as string);
    info.key !== selectedKey && history.push(info.key as string);
  };

  useEffect(() => {
    console.log("location", location, selectedKey, window.history);
    // location.pathname !== "/main/activity_manage/activity_detail" &&
    //   location.pathname.indexOf("/main/activity-details") === -1 &&
    setSelectedKey(() => {
      console.log("location", "did");
      return location?.pathname;
    });
  }, [location]);

  return (
    <Menu
      mode="inline"
      onClick={handleClickSiderMenu}
      selectedKeys={[selectedKey]}
    >
      {routes?.map((item) => {
        if (item.hideMenu)
          return <React.Fragment key={item.path}></React.Fragment>;
        if (item?.routes) {
          return (
            <SubMenu
              key={(parentPath || "") + (item?.path || "")}
              title={item.title}
              icon={item?.icon && <Icon component={item?.icon} />}
            >
              {item?.routes?.map((subItem, idx) =>
                subItem.hideMenu ? (
                  <Menu.Item
                    key={`${parentPath || ""}${item.path}${subItem.path}`}
                    style={{ display: "none" }}
                  >
                    {subItem.title}
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key={`${parentPath || ""}${item.path}${subItem.path}`}
                  >
                    {subItem.title}
                  </Menu.Item>
                )
              )}
            </SubMenu>
          );
        }
        return (
          <Menu.Item
            key={(parentPath || "") + (item?.path || "")}
            icon={item?.icon && <Icon component={item?.icon} />}
          >
            {item.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default ContainerMenu;
