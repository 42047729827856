module.exports = {
  env: {
    FLX_APP_URL: "/dev",
    FLX_APP_TB_URL: "https://manage-zdb.jiandandian.com/tb-zdb-api-test",
    FLX_APP_JD_URL: "https://manage-zdb.jiandandian.com/jd-zdb-api-test",
    FLX_APP_JY_URL: "https://manage-zdb.jiandandian.com/jyhd-api",
    FLX_APP_JZ_URL: "https://manage-zdb.jiandandian.com/jzone-api",
    PUBLIC_URL: "/zdb-manage-test/",
  },
  router: {
    basename: "zdb-manage-test",
  },
};
