/*
 * @Author: 最刚
 * @Date: 2020-07-24 17:33:46
 * @LastEditTime: 2021-07-14 10:54:45
 * @LastEditors: Joyuan
 * @Description: api
 */

import { UrlHelper } from "@flx/utils";

const PREFIX = "/mt_redemption";
const PREFIXJY = "jd/inner";
const JYINPREFIX = "/jd/inner";
const JYOSS = "/jd/seller";

let stand = "";

let flag = Number(sessionStorage.getItem("flag"));
switch (flag) {
  case 1:
    stand = "tb";
    break;
  default:
    stand = "jd";
    break;
}

const getUrl = (url: string, prefix: string = PREFIX) => {
  // const key = "ptFeEO94Z0BLiXabst"; //本地
  // const key = "JZLP3icpd53vE5jZXewdw"; //线上
  const key =
    process.env.FLX_APP_URL === "/dev"
      ? "ptFeEO94Z0BLiXabst"
      : "JZLP3icpd53vE5jZXewdw";
  return UrlHelper.connectUrlWithParams(`${prefix}${url}`, {
    INNER_PWD: key,
  });
};
const getJyUrl = (
  url: string,
  prefix: string = PREFIXJY,
  key: string = "AJE3MXY0DSF7Q"
) => {
  // const key = "aje3mxy0dsf7q"; //本地
  // const key = "AJE3MXY0DSF7Q"; //线上
  key = process.env.FLX_APP_URL === "/dev" ? "aje3mxy0dsf7q" : "AJE3MXY0DSF7Q";
  return UrlHelper.connectUrlWithParams(`${prefix}${url}`, {
    INNER_PWD: key,
  });
};

const api = {
  EDIT_ACTIVE_SUCAI: getUrl(
    "/inner/mt_activity/v1/simple_update_publish_activity"
  ), //tb追单宝活动管理-活动列表操作编辑
  GET_ACTIVE_INFO: getUrl("/tb/analyze/value/get_activity_info"), //tb追单宝活动管理-活动列表获取
  LOGIN: getUrl("/inner/value/login"), //tb追单宝登陆
  POST_TEST: getUrl(`/${stand}/seller/create_notice`), //tb追单宝后台公告--创建
  GET_NOTICE: getUrl(`/${stand}/seller/get_manage_notice`), //tb追单宝后台公告--获取
  DELETE_NOTICE: getUrl(`/${stand}/seller/del_notice`), //tb追单宝后台公告--删除
  EDIT_NOTICE: getUrl(`/${stand}/seller/update_notice`), //tb追单宝后台公告--编辑
  GET_ACTIVITY_INFO: getUrl("/inner/get_activity_list"), //tb追单宝活动管理-活动列表获取
  GET_ACTIVITY_DATA: getUrl(
    `/inner/get_activity_data?${stand === "tb" ? "platformId=5" : ""}`
  ), //tb追单宝活动管理-活动列表子页面的活动数据获取
  GET_ACTIVITY_CUSTOM: getUrl("/inner/get_card_record_list"), //tb追单宝活动管理-活动列表子页面的参与客户数据获取
  GET_ACTIVITY_RULES: getUrl("/inner/mt_activity/v1/info"), //tb追单宝活动管理-活动列表子页面的参与活动规则获取
  GET_ACTIVITY_GOODS: getUrl("/jd/seller/mt-shop-item/on-sale/admin/item"), //tb追单宝活动管理-活动列表子页面的参与活动规则goods
  GET_ACTIVITY_RULES_MEMBER: getUrl(
    `/${stand}/inner/member/grades?platformId=${stand === "tb" ? "5" : "11"}&`
  ), //tb追单宝活动管理-活动列表子页面的参与活动规则获取-会员获取
  GET_ACTIVITY_CATEGORY: getUrl(
    `/${stand}/inner/category/tree?platformId=${stand === "tb" ? "5" : "11"}&`
  ), //tb追单宝活动管理-活动列表子页面的参与活动规则获取-类目
  GET_ACTIVITY_WHITE: getUrl(`/${stand}/white_list/inner/getWhiteList`), //tb追单宝活动管理-活动列表子页面的参与活动规则-白名单
  GET_SHOP_BASIC_DATA: getUrl("/inner/get_base_info"), //获取店铺基础数据
  GET_SHOP_BASIC_DATA_DETAILS: getUrl("/inner/get_details_list"), //获取店铺基础数据-用户详情
  GET_SHOP_ACTIVITY_DATA: getUrl("/inner/get_shop_info"), //获取店铺活动数据
  GET_SHOP_TEMPLATE_DATA: getUrl("/inner/get_shop_template_info"), //获取店铺模版数据
  GET_SHOP_CARD_DATA: getUrl("/inner/get_shop_card_info"), //获取店铺card数据
  GET_PRODUCTS_FEATURED_DATA: getUrl("/inner/get_shop_invalid_info"), //获取店铺数据（活动过期页商品推荐page)
  GET_PRODUCTS_FEATURED: getUrl(
    `${
      stand === "tb"
        ? "/inner/tb/recommended_items"
        : "/jd/buyer/after_buy/v1/recommended_items"
    }`
  ), //获取店铺数据（商品推荐)

  DATA_STATISTICS: getUrl("/inner/get_analyze_data"), //数据中心-数据统计
  TEMPLATE_DATA: getUrl("/inner/get_analyze_template_data"), //数据中心-模版效果
  ANALYZE_ALL_DATA: getUrl("/inner/get_analyze_all_data"), //数据中心 -数据看板
  CARD_DATA: getUrl("/inner/get_analyze_card_data"), //数据中心 -卡片效果
  EXPORT_DAY_INFO: getUrl("/tb/analyze/export"), //按天导出用户的点击率数据
  EXPORT_NEWADD_INFO: getUrl("/tb/analyze/export_new_user"), //按每日新增用户数据导出

  GET_EXCELLENT_BUSINESS: getUrl("/inner/get_case_page"), //获取优秀商家案例
  // UPLOAD_PICTURE: getUrl("/resource/jd_picture_upload?type=6&"), //案例上传图片
  UPLOAD_PICTURE: getUrl(
    `/inner/resource/${
      stand === "tb" ? "ali_picture_upload" : "jd_picture_upload"
    }?type=6&`
  ), //案例上传图片  /mt_redemption/resource/ali_picture_upload
  GET_CATEGORY_LIST: getUrl(`/inner/${stand}/get_category_list`), //案例类目
  DELETE_BUSINESS_CASE: getUrl("/inner/delete_case"), //删除案例
  ADD_BUSINESS_CASE: getUrl("/inner/add_case"), //增加案例
  EDIT_BUSINESS_CASE: getUrl("/inner/update_case"), //修改案例

  GET_FUNNELDATA: getUrl("/inner/tb/get_set_activity_data"), // 获取漏斗数据
  GET_MARKETING_NOTICE: getUrl(`/${stand}/seller/get_manage_marketing_notice`), //运营公告
  ADD_MARKETING_NOTICE: getUrl(`/${stand}/seller/create_marketing_notice`), //添加运营公告
  EDIT_MARKETING_NOTICE: getUrl(`/${stand}/seller/update_marketing_notice`), //修改运营公告
  GET_FIRST_HOME: getUrl(`/${stand}/seller/get_manage_first_home`), //首页弹框
  ADD_FIRST_HOME: getUrl(`/${stand}/seller/create_first_home`), //添加首页弹框
  EDIT_FIRST_HOME: getUrl(`/${stand}/seller/update_first_home`), //修改首页弹框
  GET_BANNER: getUrl(`/${stand}/seller/get_manage_banner`), //banner弹框
  ADD_BANNER: getUrl(`/${stand}/seller/create_banner`), //添加banner弹框
  EDIT_BANNER: getUrl(`/${stand}/seller/update_banner`), //修改banner弹框
  GET_NOTICE_TIMES: getUrl(`/inner/get_marketing_notice_click_list`), //公告查看点击次数
  CREAT_WHITE_LIST: getUrl(`/${stand}/seller/add_notice_white`), //添加白名单
  SELECT_WHITE_LIST: getUrl(`/${stand}/seller/query_notice_white_list`), //查询白名单
  DELETE_WHITE_LIST: getUrl(`/${stand}/seller/delete_notice_white`), //白名单删除
  GET_OPERATE_DATA: getUrl("/inner/get_analyze_data_desc"), //运营数据
  GET_OPERATE_DATA2: getUrl("/inner/get_analyze_data_sum_desc"), //运营数据2
  GET_SHOP_SEARCH: getUrl("/inner/push_exp_list"), //单店铺查询
  GET_SHOP_CONTRAST: getUrl("/inner/get_shop_contrast_analyze_data"), //店铺对比
  GET_SHOP_CONTRASTS: getUrl("/inner/get_shop_contrast_list"), //店铺对比分类对比
  GET_ANALYZE_DATA: getUrl("/inner/get_shop_analyze_data_list"), //数据中心 - 运营数据 - 头部商家数据展示
  GET_OPERATE_ACTIVITY_DATA: getUrl("/inner/get_popup_click_list"), //营销活动数据1
  GET_OPERATE_ACTIVITY_DATA2: getUrl("/inner/get_popup_click_log"), //营销活动数据2
  GET_WHITE_LIST: getUrl("/inner/seller/shop_function/page"), //查询白名单列表
  ADD_WHITE_LIST: getUrl("/inner/seller/shop_function/add"), //新增白名单
  EDIT_WHITE_LIST: getUrl("/inner/seller/shop_function/edit"), //编辑白名单
  TEST_STATUS: getUrl(`/${stand}/seller/shop_function/check`), //检测功能状态
  SEND_RECORD: getUrl(`/inner/log/get_order_push_page`), //发送记录
  SEND_RECORD_DETAIL: getUrl(`/inner/log/get_order_push_details`), //发送记录详情
  GET_MATERIAL: getUrl(`/inner/activity_content/page`), //获取素材
  ADD_MATERIAL: getUrl(`/inner/activity_content/sys/add`), //增加素材
  DELETE_MATERIAL: getUrl(`/inner/activity_content/sys/del`), //删除素材
  CHANGE_STATUS: getUrl(`/inner/activity_content/sys/status/update`), //更改素材状态
  CHANGE_SORT: getUrl(`/inner/activity_content/sys/priority/update`), //更改优先级
  GET_LOGIN_RECORD: getUrl(`/inner/log/user_log_list`), //登陆记录
  MATERIAL_ADUIT: getUrl("/inner/content/examine"), //素材审核
  MATERIAL_ADUIT_LIST: getUrl("/inner/content/examine_list"), //素材审核列表
  PUSH_DATA_SEARCH: getUrl("/inner/push_exp_list"), //京东曝光数据查询
  SEARCH_ITEM_WAREID: getUrl(
    `${
      stand === "tb"
        ? "/tb/seller/mt-shop-item/admin/by_id"
        : "/jd/seller/mt-shop-item/on-sale/admin/item/wareId"
    }`
  ), //根据id查询商品
  //京云
  ACTIVITY_TEMPLATE_LIST: getJyUrl("/template/page"), //活动模版管理列表
  ACTIVITY_TYPECODE_LIST: getJyUrl("/template/activity_type/list"), //活动模版管理列表
  EDIT_ACTIVITY_TEMPLATE: getJyUrl("/template/modify"), //修改模版
  TEMPLATE_SHOWHIDE: getJyUrl("/template/load_and_unload"), //模版上下架
  TEMPLATE_UPDOWN: getJyUrl("/template/move_down_and_up"), //模版上下移
  ACTIVITY_SKIN_LIST: getJyUrl("/template_skin/page"), //活动皮肤管理列表
  EDIT_ACTIVITY_SKIN: getJyUrl("/template_skin/modify"), //修改皮肤
  SKIN_SHOWHIDE: getJyUrl("/template_skin/load_and_unload"), //皮肤上下架
  SKIN_UPDOWN: getJyUrl("/template_skin/move_down_and_up"), //皮肤上下移
  SEARCH_TEMP_DETAILS: getJyUrl("/template/query"), //查询模版详情
  SEARCH_SKIN_DETAILS: getJyUrl("/template_skin/query"), //查询皮肤详情
  SEARCH_TAG: getJyUrl("/template_tag/list"), //查询标签
  CREAT_TAG: getJyUrl("/template_tag/add_or_modify"), //创建标签
  DELETE_TAG: getJyUrl("/template_tag/del"), //删除标签
  LIST_OF_TAG: getJyUrl("/template_tag/page"), //标签列表
  UPLOAD_PICTURE_JY: getJyUrl("/oss/test", JYOSS), //京云上传图片
  //公共资源位配置
  GET_LOACTION_CONFIG: "/config/m/resource/allocation/query", //获取位置配置
  UPDATE_ALLOCATION_STATUS: "/config/m/resource/update/status", //更新资源位状态
  MODULR_OFRESOURCE: "/config/m/resource/module", //获取type对应的资源模版
  UPDATE_RESOURCE: "/config/m/resource/update", //更新资源
  CREATE_RESOURCE: "/config/m/resource/insert", //创建资源
  DELETE_RESOURCE: "/config/m/resource/delete", //删除单个资源
  SEARCH_SINGLE_RESOURCE: "/config/m/resource/get", //查询单个资源
  SEARCH_ALL_RESOURCE: "/config/m/resource/page", //查询所有资源

  // 京赢
  SEARCH_STORE_LIST: getJyUrl("/merchant/query/page", JYINPREFIX), //京赢店铺列表查询
  ACTIVITY_LIST_JYIN: getJyUrl("/activity/query/page", JYINPREFIX), //京赢活动列表
  GET_ACTIVITY_DETAILS: getJyUrl("/activity/query/details", PREFIXJY), //活动详情
  FIND_WECHAT_TEMPLATE: getJyUrl("/wechat_news/find_template", PREFIXJY),
  ACTIVITY_CHILD_INFO: getJyUrl("/activity/route_icon/list", PREFIXJY), //
  SEARCH_ITEM_LIST: getJyUrl("/item/info", PREFIXJY), //
  SELECT_DETAIL_PRIZE: getJyUrl("/activity/query/record", PREFIXJY), //
  ANALYSIS_SINGLE_ACTIVITY_DATA: getJyUrl("/data/activity_data", PREFIXJY), //
  ACTIVITY_CHANNEL_LIST: getJyUrl("/activity/channel/list", PREFIXJY), //
  JZONE_LOGIN_RECORDS: getJyUrl("/merchant/page", PREFIXJY), //查询登录记录
  DRAFT_ACTIVITY_LIST: getJyUrl("/activity/drafts/page", PREFIXJY), //查询草稿箱活动
  QUERY_ACTIVITY_TASK_LIST: getJyUrl("/activity_task_type/queryList", PREFIXJY), //活动任务列表
  SELECT_ITEMINFO_SKU: getJyUrl("/item/query/sku/ware", PREFIXJY), //通过skuId查询sku信息
  SELECT_ITEM_INFO: getJyUrl("/activity/item/ref/by_task_id"), //获取商品item信息
};

export default api;
