import { lazy } from "react";
import { RoutesOption } from "@flx/react-router";
import Container from "@components/container";
// import NoMatch from "@pages/base/NoMatch";
import Login from "@pages/login";
import Banner from "@/pages/notice/Banner";
const NoticeManage = lazy(() => import("@/pages/notice/index"));
const ActivityList = lazy(() => import("@/pages/activity-manage/activityList"));
const Details = lazy(() => import("@/pages/activity-manage/activityDetail"));
const BasicData = lazy(() => import("@/pages/shop-data/BasicData"));
const ShopDataActivity = lazy(
  () => import("@/pages/shop-data/ShopDataActivity")
);
const ShopDataPlate = lazy(() => import("@/pages/shop-data/ShopDataPlate"));
const ExportInfo = lazy(() => import("@/pages/exportCenter"));
const ActivityManage = lazy(() => import("@/pages/activity-manage"));
const DataKanban = lazy(() => import("@/pages/dataCenter/dataKanban"));
const DataStatistics = lazy(() => import("@/pages/dataCenter/dataStatistics"));
const TemplateEffect = lazy(() => import("@/pages/dataCenter/templateEffect"));
const ProductsFeatured = lazy(
  () => import("@pages/shop-data/ShopProductsFeatured")
);
const ShopDataCard = lazy(() => import("@pages/shop-data/ShopDataCard"));
const CardEffect = lazy(
  () => import("@pages/dataCenter/cardEffect/CardEffect")
);
const ExcellentBusinessCase = lazy(
  () => import("@pages/excellent-business-case/ExcellentBusinessCase")
);
const NormalBusinessCase = lazy(
  () => import("@pages/excellent-business-case/NormalBUsinessCase")
);
const FunnelData = lazy(() => import("@pages/dataCenter/funnelData"));
const IndexModal = lazy(() => import("@pages/notice/indexModal"));
const BannerModal = lazy(() => import("@pages/notice/Banner"));
const SpecialModal = lazy(() => import("@pages/notice/SpecialModal"));
const OperationData = lazy(() => import("@pages/dataCenter/operationData"));
const ShopDataContrast = lazy(
  () => import("@pages/dataCenter/shopDataContrast")
);
const FreeNewUsersDot = lazy(
  () => import("@pages/operation-activity-data/freeNewUsersDot")
);
const SoftwareReminderDot = lazy(
  () => import("@pages/operation-activity-data/softwareReminderDot")
);
const LoginRecord = lazy(() => import("@pages/shop-data/login-record"));
const FreeNewUserDetailed = lazy(
  () => import("@pages/operation-activity-data/freeNewUserDetailed")
);
const SoftwareReminderDetailed = lazy(
  () => import("@pages/operation-activity-data/softwareReminderDetailed")
);
const CustomActivityList = lazy(() => import("@pages/custom-activity"));
const SendRecord = lazy(() => import("@pages/activity-manage/send-reord"));
const Material = lazy(
  () => import("@pages/custom-activity/custom-activities/CustomActivities")
);
const ResAllocate = lazy(
  () => import("@pages/resource-manage/resource-allocation")
);
const SetActivity = lazy(() => import("@/pages/set-activity/set-label"));
const SetTemplate = lazy(() => import("@pages/set-activity/set-template"));
const SetSkin = lazy(() => import("@pages/set-activity/set-skin"));
const ContentAudit = lazy(() => import("@pages/content-audit/index"));
const StoreList = lazy(
  () => import("@pages/store-manage/store-list/store-list")
);
const JyActivityList = lazy(
  () => import("@/pages/jingyin-activity-list/index")
);
const JzDetails = lazy(() => import("@pages/jzone-details"));
const JzLoginRecords = lazy(
  () => import("@pages/jzone-activity/login-records")
);

const flag = Number(sessionStorage.getItem("flag"));
let routes: RoutesOption[] = [
  {
    path: "/login",
    component: Login,
    title: "登录",
  },
  {
    path: "/main",
    component: Container,
    routes: [
      {
        path: "/shop_data",
        title: "店铺数据",
        routes: [
          {
            path: "/basic_data",
            component: BasicData,
            title: "基本数据",
          },
          {
            path: "/shop_data_activity",
            component: ShopDataActivity,
            title: "店铺数据(活动)",
          },
          {
            path: "/shop_data_plate",
            component: ShopDataPlate,
            title: "店铺数据(模版)",
          },
          {
            path: "/shop_data_card",
            component: ShopDataCard,
            title: "店铺数据(卡片)",
          },
          {
            path: "/shop_products_featured",
            component: ProductsFeatured,
            title: "店铺数据(活动过期页商品推荐)",
          },
          {
            path: "/login_record",
            component: LoginRecord,
            title: "登录记录",
          },
        ],
      },
      {
        path: "/activity_manage",
        title: "活动管理",
        routes: [
          {
            path: "/activity_list",
            component: ActivityList,
            title: "活动列表",
          },
          {
            path: "/activity_replace",
            component: ActivityManage,
            title: "活动素材替换",
          },
          {
            path: "/send_record",
            component: SendRecord,
            title: "发送记录",
          },
          {
            path: "/activity_detail",
            component: Details,
            title: "活动详情",
            hideMenu: true,
          },
        ],
      },
      {
        path: "/activity_marketing",
        title: "营销活动",
        routes: [
          {
            path: "/notice_manage",
            component: NoticeManage,
            title: "公告管理",
          },
          {
            path: "/index_modal",
            component: IndexModal,
            title: "首页弹框",
          },
        ],
      },
      {
        path: "/data_center",
        title: "数据中心",
        routes: [
          {
            path: "/data_kanban",
            component: DataKanban,
            title: "数据看板",
          },
          {
            path: "/data_statistics",
            component: DataStatistics,
            title: "数据统计",
          },
          {
            path: "/template_effect",
            component: TemplateEffect,
            title: "模板效果",
          },
          {
            path: "/data_cardeffect",
            component: CardEffect,
            title: "卡片效果",
          },
          {
            path: "/data_funnel",
            component: FunnelData,
            title: "漏斗数据",
          },
          {
            path: "/operation_data",
            component: OperationData,
            title: "运营数据",
          },
          // {
          //   path: "/shopData_contrast",
          //   component: ShopDataContrast,
          //   title: "店铺数据对比",
          // },
        ],
      },
      {
        path: "/operational_activity",
        title: "运营活动数据",
        routes: [
          {
            path: "/new_user_dot",
            component: FreeNewUsersDot,
            title: "免费新用户弹框打点数据",
          },
          {
            path: "/new_user_detailed",
            component: FreeNewUserDetailed,
            title: "免费新用户弹框弹出明细",
          },
          {
            path: "/software_dot",
            component: SoftwareReminderDot,
            title: "软件过期提醒弹框打点数据",
          },
          {
            path: "/software_detailed",
            component: SoftwareReminderDetailed,
            title: "软件过期提醒弹框弹出明细",
          },
        ],
      },
      {
        path: "/export_center",
        title: "导出中心",
        routes: [
          {
            path: "/export_info",
            component: ExportInfo,
            title: "导出数据",
          },
        ],
      },
      {
        path: "/business_case",
        title: "商家案例",
        routes: [
          {
            path: "/excellent_business_case",
            component: ExcellentBusinessCase,
            title: "优秀商家案例",
          },
          {
            path: "/normal_business_case",
            component: NormalBusinessCase,
            title: "普通商家案例",
          },
        ],
      },
      {
        path: "/custom",
        title: "自定义活动",
        routes: [
          {
            path: "/white/list",
            component: CustomActivityList,
            title: "白名单列表",
          },
          {
            path: "/material",
            component: Material,
            title: "素材",
          },
        ],
      },
      {
        path: "/audit",
        title: "内容审核",
        routes: [
          {
            path: "/Content_audit",
            component: ContentAudit,
            title: "内容审核",
          },
        ],
      },
    ],
  },
  // {
  //   path: "/404",
  //   component: NoMatch,
  //   title: "错误啦",
  // },
];

flag === 2 &&
  (routes = [
    {
      path: "/login",
      component: Login,
      title: "登录",
    },
    {
      path: "/main",
      component: Container,
      routes: [
        {
          path: "/shop_data",
          title: "店铺数据",
          routes: [
            {
              path: "/basic_data",
              component: BasicData,
              title: "基本数据",
            },
            {
              path: "/shop_data_activity",
              component: ShopDataActivity,
              title: "店铺数据(活动)",
            },
            {
              path: "/shop_data_plate",
              component: ShopDataPlate,
              title: "店铺数据(模版)",
            },
            {
              path: "/shop_products_featured",
              component: ProductsFeatured,
              title: "店铺数据(活动过期页商品推荐)",
            },
            {
              path: "/login_record",
              component: LoginRecord,
              title: "登录记录",
            },
          ],
        },
        {
          path: "/activity_manage",
          title: "活动管理",
          routes: [
            {
              path: "/activity_list",
              component: ActivityList,
              title: "活动列表",
            },
            {
              path: "/activity_replace",
              component: ActivityManage,
              title: "活动素材替换",
            },
            {
              path: "/send_record",
              component: SendRecord,
              title: "发送记录",
            },
            {
              path: "/activity_detail",
              component: Details,
              title: "活动详情",
              hideMenu: true,
            },
          ],
        },
        {
          path: "/activity_marketing",
          title: "营销活动",
          routes: [
            {
              path: "/notice_manage",
              component: NoticeManage,
              title: "公告管理",
            },
            {
              path: "/index_modal",
              component: IndexModal,
              title: "首页弹框",
            },
            {
              path: "/banner_modal",
              component: BannerModal,
              title: "banner",
            },
            {
              path: "/special_modal",
              component: SpecialModal,
              title: "首页特殊弹框",
            },
          ],
        },
        {
          path: "/data_center",
          title: "数据中心",
          routes: [
            {
              path: "/data_kanban",
              component: DataKanban,
              title: "数据看板",
            },
            {
              path: "/data_statistics",
              component: DataStatistics,
              title: "数据统计",
            },
            {
              path: "/data_cardeffect",
              component: CardEffect,
              title: "卡片效果",
            },
            {
              path: "/template_effect",
              component: TemplateEffect,
              title: "模板效果",
            },
            {
              path: "/data_funnel",
              component: FunnelData,
              title: "漏斗数据",
            },
            {
              path: "/operation_data",
              component: OperationData,
              title: "运营数据",
            },
            {
              path: "/shopData_contrast",
              component: ShopDataContrast,
              title: "店铺数据对比",
            },
          ],
        },
        {
          path: "/export_center",
          title: "导出中心",
          routes: [
            {
              path: "/export_info",
              component: ExportInfo,
              title: "导出数据",
            },
          ],
        },
        {
          path: "/business_case",
          title: "商家案例",
          routes: [
            {
              path: "/excellent_business_case",
              component: ExcellentBusinessCase,
              title: "优秀商家案例",
            },
            {
              path: "/normal_business_case",
              component: NormalBusinessCase,
              title: "普通商家案例",
            },
          ],
        },
        {
          path: "/custom",
          title: "自定义活动",
          routes: [
            {
              path: "/white/list",
              component: CustomActivityList,
              title: "白名单列表",
            },
            {
              path: "/material",
              component: Material,
              title: "素材",
            },
          ],
        },
      ],
    },
    // {
    //   path: "/404",
    //   component: NoMatch,
    //   title: "错误啦",
    // },
  ]);

(flag === 3 || flag === 4) &&
  (routes = [
    {
      path: "/login",
      component: Login,
      title: "登录",
    },
    {
      path: "/main",
      component: Container,
      routes: [
        {
          path: "/resource-manage",
          title: "资源位管理",
          routes: [
            {
              path: "/resource_allocation",
              component: ResAllocate,
              title: "资源位配置",
            },
          ],
        },
        {
          path: "/set_activity",
          title: "活动配置",
          routes: [
            {
              path: "/set_label",
              component: SetActivity,
              title: "标签配置",
            },
            {
              path: "/set_template",
              component: SetTemplate,
              title: "模版配置",
            },
            {
              path: "/set_skin",
              component: SetSkin,
              title: "皮肤配置",
              hideMenu: true,
            },
          ],
        },
        {
          path: "/store_manage",
          title: "店铺管理",
          routes: [
            {
              path: "/store_list",
              component: StoreList,
              title: "店铺列表",
            },
            {
              path: "/jzone-login-records",
              component: JzLoginRecords,
              title: "登入记录",
            },
          ],
        },
        {
          path: "/activity-manage",
          title: "活动管理",
          routes: [
            {
              path: "/activity-list",
              component: JyActivityList,
              title: "活动列表",
            },
          ],
        },
        {
          path:
            "/activity-details/:activityId/:community/:managerId/:thirdShopId",
          title: "活动详情",
          component: JzDetails,
          hideMenu: true,
        },
      ],
    },
  ]);

export default routes;
