/*
 * @Author: 最刚
 * @Date: 2020-07-24 17:33:38
 * @LastEditTime: 2021-01-21 10:01:57
 * @LastEditors: Joyuan
 * @Description:
 */

import api from "./index";
import { POST } from "@flx/request";
import { LoginParams } from "../api/interface/some.interface";

/**
 * 登录接口
 * */
export const login = (params: LoginParams) => {
  return POST(api.LOGIN, params);
};
