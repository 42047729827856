const { resolve } = require("path");
const { combineConfig } = require("@flx/react-scripts");

const config = {
  cssModules: true,
  stylusLoaderOption: {
    stylusOptions: {
      import: [
        resolve("src/assets/stylus/variable.styl"),
        resolve("src/assets/stylus/common.styl"),
      ],
    },
  },
  lessLoaderOption: {
    lessOptions: {
      modifyVars: {
        "primary-color": "#3D7FFF",
      },
      javascriptEnabled: true,
    },
  },
  router: {
    defaultPath: "/login",
    basename: "", // 项目名称
  },
};

module.exports = function () {
  return combineConfig(config);
};
