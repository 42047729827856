import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { login } from "../../api/login";
import styles from "./index.module.styl";
import { changeBtn } from "@/recoil/atoms";
import { useRecoilState } from "recoil";

interface LoginProps {}

function Login(props: LoginProps) {
  const [jot, setJot] = useRecoilState(changeBtn);

  useEffect(() => {
    process.env.FLX_APP_ENV === "test"
      ? (() => {
          sessionStorage.setItem("flag", "4");
          setJot("jz");
        })()
      : (() => {
          sessionStorage.setItem("flag", "1");
          setJot("tb");
          (jot === "jy" || jot === "jz") && window.location.reload();
        })();
  }, []);

  useEffect(() => {
    process.env.FLX_APP_ENV === "test" &&
      (jot === "jy" || jot === "jz") &&
      (() => {
        window.location.href =
          "/zdb-manage-test/main/set_activity/set_template";
      })();
  }, [jot]);

  //提交表单且数据验证成功后回调事件
  const onFinish = (values: any) => {
    const params = {
      nick: values.username,
      password: values.password,
    };
    //查看是否取得数据
    console.log(params);
    //axios交互数据
    login(params)
      .then((res) => {
        console.log(res);
        window.location.href = "/main/shop_data/basic_data";
      })
      .catch((err) => {
        message.error("登录失败");
        console.log(err);
      });
  };

  return (
    <Form
      name="normal_login"
      className={styles.loginform}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your Username!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className={styles.siteFormItemIcon} />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className={styles.siteFormItemIcon} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.loginFormButton}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Login;
