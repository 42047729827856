import { menuKey, changeBtn } from "@/recoil/atoms";
import { RoutesOption } from "@flx/react-router";
import { Button, Layout, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styles from "./header.module.styl";
import { useLocation, useHistory } from "react-router-dom";

const { Header } = Layout;
const { Option, OptGroup } = Select;
const type: { [props: string]: string } = {
  tb: "淘宝",
  jd: "京东",
  jy: "京云",
  jz: "京赢",
};

interface ContainerMenuInterface {
  routes?: RoutesOption[];
  parentPath?: string;
}

function ContainerHeader(props: ContainerMenuInterface) {
  let location = useLocation();
  let history = useHistory();
  const [selectedKey, setSelectedKey] = useRecoilState(menuKey);
  const [jot, setJot] = useRecoilState(changeBtn);
  const [target, setTarget] = useState("");
  const { routes, parentPath } = props;

  // let target = "";
  // let target1 = "";
  // let target2 = "";

  useEffect(() => {
    if (routes) {
      for (let item of routes) {
        if (item.hasOwnProperty("routes")) {
          item.routes?.forEach((sItem: any) => {
            const a =
              (parentPath || "") + (item?.path || "") + (sItem?.path || "");
            if (a === location?.pathname) {
              console.log("111", sItem.title);
              setTarget(sItem.title);
              return sItem.title;
            }
          });
        } else {
          routes?.forEach((item: any) => {
            const b = (parentPath || "") + (item?.path || "");
            if (b === location?.pathname) {
              console.log("2222", item.title);
              setTarget(item.title);
              return item.title;
            }
          });
        }
      }
    }
    location?.pathname.indexOf("/main/activity-details") !== -1 &&
      setTarget("活动详情");
  }, [location?.pathname]);

  console.log(target, selectedKey, location);
  return (
    <Header className={styles.headerWrapper}>
      <div className={styles.logoWrapper}>
        {jot.toUpperCase()}
        {jot === "tb" || jot === "jd" ? "追单宝" : ""}管理后台
      </div>
      <div
        className={styles.logoWrapper}
        style={{
          backgroundColor: "transparent",
          color: "#fff",
          width: "auto",
          fontSize: "20px",
          fontWeight: 900,
        }}
      >
        {(target === "活动详情" || target === "皮肤配置" || true) && (
          <Button
            type="text"
            style={{ color: "#fff", lineHeight: "25px" }}
            onClick={() => history.go(-1)}
          >
            {"<<返回"}
          </Button>
        )}
        {target}
      </div>
      <div style={{ position: "absolute", color: "#fff", right: "50px" }}>
        <label>切换： </label>
        <Select
          value={type[jot]}
          onChange={(key: string) => {
            sessionStorage.setItem("flag", key);
            window.location.reload();
          }}
          style={{ width: "80px" }}
        >
          <OptGroup label="追单宝">
            <Option value="1">淘宝</Option>
            <Option value="2">京东</Option>
          </OptGroup>
          <OptGroup label="京云类">
            <Option value="3">京云</Option>
            <Option value="4">京赢</Option>
          </OptGroup>
        </Select>
      </div>
    </Header>
  );
}
export default ContainerHeader;
