/*
 * @Author: Joyuan
 * @Date: 2021-03-03 19:42:11
 * @LastEditTime: 2021-05-28 11:36:03
 * @LastEditors: Joyuan
 * @Description:
 */
export enum ERROR_CODE {
  LOGIN_EXPIRED = "ul_530", // 登录过期
  PSD_ERROR = "ul_531",
}
