import React, { useEffect } from "react";
import routes from "./router/routes";
import { message } from "antd";
import "antd/dist/antd.less";
import { FlxApp } from "@flx/react";
import { changeBtn } from "@/recoil/atoms";
import { useRecoilState } from "recoil";
import { ERROR_CODE } from "@common/request/config";

const App: React.FC = () => {
  const [jot, setJot] = useRecoilState(changeBtn);
  console.log(jot, process.env);
  return (
    <FlxApp
      requestConfig={{
        onError: (response) => {
          let tokenWarning = false;
          if (
            response.data.errorCode === ERROR_CODE.LOGIN_EXPIRED ||
            response.data.errorCode === ERROR_CODE.PSD_ERROR
          ) {
            if (!tokenWarning) {
              tokenWarning = true;
              window.location.href = "/login";
            }
            return Promise.reject(response.data);
          }
          message.error(response?.data?.errorMessage);
        },
        onLoginExpired: () => {
          console.log("byebye");
          window.location.href = "/login";
        },
        baseURL:
          jot === "tb"
            ? process.env.FLX_APP_TB_URL
            : jot === "jy"
            ? process.env.FLX_APP_JY_URL
            : jot === "jd"
            ? process.env.FLX_APP_JD_URL
            : process.env.FLX_APP_JZ_URL,
      }}
      routes={routes}
    ></FlxApp>
  );
};

export default App;
