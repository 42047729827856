import { atom } from "recoil";
import keys from "./keys";
import { historyPushEffect } from "./effects";

const flag = sessionStorage.getItem("flag");
// 左侧菜单值
export const menuKey = atom({
  key: keys.MENU_KEY,
  default: "",
  // effects_UNSTABLE: [historyPushEffect()],
  // effects_UNSTABLE: [localStorageEffect(keys.MENU_KEY)],
});

//淘宝、京东系统切换
export const changeBtn = atom({
  key: "JOT",
  default: `${
    flag === "1" ? "tb" : flag === "2" ? "jd" : flag === "3" ? "jy" : "jz"
  }`,
});
